import React from "react";
import PhoneBlack from "../../images/icons/phoneBlack.svg";
import BlackEmail from "../../images/icons/emailBlack.svg";

function CallToAction() {
  return (
    <div id="CallToAction">
      <h1>Professional Move Out Cleaners Calgary</h1>
      {/* 
      <div className="container">
        <div className="cta-num">
          <img src={PhoneBlack} alt="phone"></img>

          Call Us: (555) 555-5555
        </div>

        <div className="cta-num">
          <img src={BlackEmail} alt="email"></img>
          Email Us: thisisanemail@email.com
        </div>
      </div>
        */}
    </div>
  );
}

export default CallToAction;
